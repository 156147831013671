var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "manager--content",
      class: { centerY: !_vm.uniqueDatesAndTypes.length },
    },
    [
      _c("div", { staticClass: "bg-grey min-h-100" }, [
        _c(
          "div",
          {
            staticClass: "market-cashflow-value flex",
            class: [
              _vm.tableWidth,
              {
                "no-data":
                  !_vm.isManagerDataAvailable ||
                  !_vm.uniqueDatesAndTypes.length,
              },
            ],
          },
          [
            _vm.showAddColumnPopup
              ? _c("BaseModal", {
                  class: { "show-modal": _vm.showAddColumnPopup },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("AddColumnPopUp", {
                              attrs: {
                                sortedManagerData: _vm.sortedManagerData,
                              },
                              on: {
                                closeOptions: _vm.hideAddColumnData,
                                addNewColumnData: _vm.addNewColumnData,
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1222545004
                  ),
                })
              : _vm._e(),
            _vm.showUnSavedMessage
              ? _c("BaseModal", {
                  class: { "show-modal": _vm.showUnSavedMessage },
                  on: { close: _vm.closeUnsavedPopUp },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("UnSavedMessage", {
                              on: {
                                cancelMove: _vm.closeUnsavedPopUp,
                                saveChanges: _vm.saveData,
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    721083903
                  ),
                })
              : _vm._e(),
            _vm.isFetchingData ? _c("LoadingOverlay") : _vm._e(),
            !_vm.uniqueDatesAndTypes.length && _vm.isManagerDataAvailable
              ? _c("div", { staticClass: "no-manager-data" }, [
                  _c("p", { staticClass: "text-center" }, [
                    _vm._v(
                      "You currently have no " +
                        _vm._s(_vm.marketOrExposureText) +
                        " data entered. Please upload a file or add assets"
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex flex--row-space-around mt-3" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex--column column-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "theme-icon flex flex--row-center",
                              attrs: { title: "Create a table" },
                              on: { click: _vm.showAddColumnData },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fal", "plus"] },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" Add asset data "),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex flex--column column-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "theme-icon flex flex--row-center",
                              attrs: { title: "Upload excel file" },
                              on: { click: _vm.goToBulkOperations },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "file-excel"] },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" Upload file "),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            !_vm.isManagerDataAvailable
              ? _c("div", { staticClass: "no-manager-data" }, [
                  _c("p", { staticClass: "message-text text-center" }, [
                    _vm._v(
                      " You currently have no " +
                        _vm._s(_vm.investmentsOrExposuresText) +
                        " in your setup. Please upload a file or update Manager Settings "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex flex--row-space-around mt-3" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex--column column-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "theme-icon flex flex--row-center",
                              attrs: { title: "Manager Settings" },
                              on: { click: _vm.goToManagerSettings },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "user-cog"] },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" Manager Settings "),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex flex--column column-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "theme-icon flex flex--row-center",
                              attrs: { title: "Upload excel file" },
                              on: { click: _vm.goToBulkOperations },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "file-excel"] },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" Upload file "),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.uniqueDatesAndTypes.length > 0
              ? _c(
                  "div",
                  { staticClass: "manager-list-wrapper" },
                  [
                    _c("ManagerTableList", {
                      attrs: {
                        managerInFocus: _vm.managerInFocus,
                        managerData: _vm.sortedManagerData,
                        showTotals: "",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.uniqueDatesAndTypes.length
              ? _c(
                  "div",
                  {
                    staticClass: "manager-table-wrapper",
                    class: _vm.isManagerDataAvailable
                      ? "table-data"
                      : "no-table-data",
                  },
                  [
                    _c(
                      "div",
                      {
                        ref: "managerTable",
                        staticClass: "manager-table-content",
                        class: {
                          "table-overflow": _vm.uniqueDatesAndTypes.length > 5,
                        },
                      },
                      [
                        _c("MarketValueAndCashflowTable", {
                          attrs: {
                            managerTableData: _vm.sortedManagerData,
                            originalManagerTableData: _vm.localManagerData,
                            columnData: _vm.uniqueDatesAndTypes,
                          },
                          on: {
                            removeColumnFromList: _vm.removeColumnFromList,
                            highlightManagerInFocus:
                              _vm.highlightManagerInFocus,
                            updateAssetValue: _vm.updateAssetValue,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "save-slide-out",
                        class: { "show-save": _vm.dataToBeSaved.length > 0 },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "slide-out-inner flex flex--row-center",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "btn btn--discard",
                                on: { click: _vm.discardChanges },
                              },
                              [_vm._v("Discard")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "btn btn--default",
                                on: { click: _vm.saveData },
                              },
                              [_vm._v("Save")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.uniqueDatesAndTypes.length
              ? _c(
                  "div",
                  {
                    staticClass: "add-column",
                    on: { click: _vm.showAddColumnData },
                  },
                  [
                    _c("div", { staticClass: "add-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "theme-icon flex flex--row-center" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fal", "plus"] },
                          }),
                        ],
                        1
                      ),
                      _c("span", [_vm._v("Add Column")]),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }