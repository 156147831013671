



























































































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import MarketValueAndCashflowApi from '@/api/marketValueAndCashflowApi';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import MarketValueAndCashflowTable from '@/components/MonitorManager/MarketValueAndCashflowTable.vue';
import ManagerTableList from '@/components/MonitorManager/ManagerList.vue';
import AddColumnPopUp from '@/components/MonitorManager/AddColumnPopUp.vue';
import ManagerItem from '@/models/AssetValueAndCashFlow';
import MarketValueAndCashflowItem from '@/models/AssetAndMarketValueItem';
import BaseModal from '@/components/BaseModal.vue';
import { compare } from '@/utils/compare';
import cloneDeep from 'lodash.clonedeep';
import dayjs from 'dayjs';
import UnSavedMessage from '@/components/MonitorManager/UnSavedMessage.vue';
import { IManagerAssetValuesAndCashflowsDto } from '@/interfaces/entity/IManagerAssetValuesAndCashflowsDto';

interface ColumnHeading {
  effectiveDate: string;
  type: string;
  total: number;
}

@Component({
    components: {
        MarketValueAndCashflowTable,
        ManagerTableList,
        LoadingOverlay,
        BaseModal,
        AddColumnPopUp,
        UnSavedMessage,
    },
})
export default class MarketValueAndCashflow extends Vue {
    get tableWidth (): Record<string, boolean> {
        return {
            'w-50': this.uniqueDatesAndTypes.length === 1,
            'w-70': this.uniqueDatesAndTypes.length === 2,
            'w-90': this.uniqueDatesAndTypes.length === 3,
            'w-100': this.uniqueDatesAndTypes.length > 3,
        };
    }

    public managerInFocus  = -1;

    public localManagerData: ManagerItem[] = [];

    public localManagerDataWithPrefills: ManagerItem[] = [];

    public isFetchingData = false;

    public showAddColumnPopup = false;

    public unsavedData = false;

    public lastAddedItem!: MarketValueAndCashflowItem<number | string, number>;

    public newColumnIndex: number | null = null;

    get currentRouteName (): string {
        return this.$route.name ?? '';
    }

    get marketOrExposureText (): string {
        return this.currentRouteName === 'MarketValueCashflow'
            ? 'market'
            : 'exposure';
    }

    get investmentsOrExposuresText (): string {
        return this.currentRouteName === 'MarketValueCashflow'
            ? 'investments'
            : 'exposures';
    }


    @Ref('managerTable') managerTable!: HTMLTableElement;

    created (): void {
        // make the initial call and update the db
        this.fetchValues();
    }


    public goToManagerSettings (): void {
        this.$router.push('/manager-settings');
    }

    public goToBulkOperations (): void {
        this.$router.push('/bulk-operations');
    }

    //#region api calls

    private setManagerData (newManagerData: ManagerItem[]) {
        this.localManagerData = cloneDeep(newManagerData);

        // fill in the gaps of the data.
        this.localManagerDataWithPrefills =
        this.generateMissingAssetData(newManagerData);

        this.isFetchingData = false;
        if (this.showUnSavedMessage) {
            this.$store.commit('updateShowUnSavedMessage', false);
        }
    }

    private async fetchValues () {
        this.isFetchingData = true;

        let response: IManagerAssetValuesAndCashflowsDto[] = [];

        try {
            if (this.routeFullPath === 'MarketValueCashflow') {
                response = await MarketValueAndCashflowApi.getClientAssetValuesAndCashflows();
            } else if (this.routeFullPath === 'ExposureValues') {
                response = await MarketValueAndCashflowApi.getClientExposureValuesAndFlows();
            }
        }
        catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error fetching data');
        } finally{
            const responseWithMeta: ManagerItem[] = this.appendMeta(response);
            this.setManagerData(responseWithMeta);
        }

    }

    public async saveData (): Promise<void> {
        this.$store.commit('updateShowUnSavedMessage', false);
        this.isFetchingData = true;
        let response: ManagerItem[] = [];
        try {

            if (this.routeFullPath === 'MarketValueCashflow') {
                response = await MarketValueAndCashflowApi.updateClientAssetValuesAndCashflows(this.dataToBeSaved);
            } else if (this.routeFullPath === 'ExposureValues') {
                response = await MarketValueAndCashflowApi.updateClientExposureValuesAndFlows(this.dataToBeSaved);
            }
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'Error saving data');
            this.fetchValues();
        }

        let metaData = cloneDeep(response);
        metaData = this.appendMeta(metaData);

        this.setManagerData(metaData);
        this.isFetchingData = false;
    }

    public discardChanges (): void {
        this.$store.commit('updateShowUnSavedMessage', false);
        this.fetchValues();
    }
    //#endregion


    private appendMeta (managerData: ManagerItem[]): ManagerItem[] {
        return managerData.map((manager: ManagerItem) => {
            const assetValuesWithMeta: MarketValueAndCashflowItem<string | number>[] = manager.assetValuesAndCashflows.map(
                (asset) => {
                    return new MarketValueAndCashflowItem<string | number>(
                        asset.type,
                        asset.rowID,
                        asset.tickerID,
                        asset.effectiveDate,
                        asset.tickerValue,
                        asset.isDelete
                    );
                }
            );
            const newManagerItem = new ManagerItem(
                manager.name,
                manager.id,
                assetValuesWithMeta,
                manager.tickerIds,
                manager.parentEntityName,
                manager.entityTypeId
            );
            return newManagerItem;
        });
    }

    private generateMissingAssetData (managerData: ManagerItem[]): ManagerItem[] {
        const allUniqueDates = this.getUniqueDatesAndTypesForBuild(managerData);
        managerData.forEach((managerItem: ManagerItem) => {
            const newMarketValueAndCashflowData: Array<MarketValueAndCashflowItem<string | number, number>> = [];
            allUniqueDates.forEach((columnHeading: ColumnHeading) => {
                const managerHasAssetForDateAndType =
            managerItem.assetValuesAndCashflows.find(
                (
                    assetItem: MarketValueAndCashflowItem<string | number, number>
                ) => {
                    return (
                        assetItem.effectiveDate === columnHeading.effectiveDate &&
                    assetItem.type === columnHeading.type
                    );
                }
            );
                let newAssetItem = managerHasAssetForDateAndType;
                if (!managerHasAssetForDateAndType) {
                    newAssetItem = new MarketValueAndCashflowItem(
                        columnHeading.type,
                        null,
                        managerItem.tickerIds[columnHeading.type],
                        columnHeading.effectiveDate,
                        '',
                        false,
                        {
                            new: true,
                            edited: false,
                            show: true,
                            isNewColumn: false,
                        }
                    );
                }
                newMarketValueAndCashflowData.push(newAssetItem);
            });
            managerItem.assetValuesAndCashflows = newMarketValueAndCashflowData;
        });
        return managerData;
    }

    private getUniqueDatesAndTypesForBuild (
        managerData: ManagerItem[]
    ): ColumnHeading[] {
        let uniqueArrayOfDates: ColumnHeading[] = [];
        managerData.forEach((managerItem: ManagerItem) => {
            managerItem.assetValuesAndCashflows.forEach(
                (
                    assetValueItem: MarketValueAndCashflowItem<number | string, number>
                ) => {
                    if (!assetValueItem.meta.show) {
                        return;
                    }
                    // not check the date and see if it is in the uniqueArrayOfDates
                    const doesArrayContainDate = uniqueArrayOfDates.find(
                        (dateObj: ColumnHeading) => {
                            return (
                                dateObj.effectiveDate === assetValueItem.effectiveDate &&
                    dateObj.type === assetValueItem.type
                            );
                        }
                    );
                    if (!doesArrayContainDate) {
                        uniqueArrayOfDates.push({
                            effectiveDate: assetValueItem.effectiveDate,
                            type: assetValueItem.type,
                            total: 0,
                        });
                    }
                }
            );
        });
        return uniqueArrayOfDates;
    }

    public highlightManagerInFocus (managerIndex: number): void {
        this.managerInFocus = managerIndex;
    }

    public closeUnsavedPopUp (): void {
        this.$store.commit('updateShowUnSavedMessage', false);
    }


    public showAddColumnData (): void {
        this.showAddColumnPopup = true;
    }

    public hideAddColumnData (): void {
        this.showAddColumnPopup = false;
    }

    //#region editing table data
    public removeColumnFromList (columnIndex: number): void {
        const columnInView = this.uniqueDatesAndTypes[columnIndex];
        this.localManagerDataWithPrefills.forEach(
            (manager: ManagerItem, managerIndex: number) => {
                const assetValueToUpdate = manager.assetValuesAndCashflows.findIndex(
                    (assetValue: MarketValueAndCashflowItem<string | number, number>) => {
                        return (
                            assetValue.effectiveDate === columnInView.effectiveDate &&
                assetValue.type === columnInView.type
                        );
                    }
                );
                if (
                    this.localManagerDataWithPrefills[managerIndex]
                        .assetValuesAndCashflows[assetValueToUpdate].meta.isNewColumn
                ) {
                    this.localManagerDataWithPrefills[
                        managerIndex
                    ].assetValuesAndCashflows.splice(assetValueToUpdate, 1);
                } else if (assetValueToUpdate >= 0) {
                    Vue.set(
                        this.localManagerDataWithPrefills[managerIndex]
                            .assetValuesAndCashflows[assetValueToUpdate],
                        'isDelete',
                        true
                    );
                    Vue.set(
                        this.localManagerDataWithPrefills[managerIndex]
                            .assetValuesAndCashflows[assetValueToUpdate],
                        'tickerValue',
                        ''
                    );
                }
            }
        );
    }

    public addNewColumnData (item: ColumnHeading): void {
        if (!item) return;

        this.localManagerDataWithPrefills.forEach((manager: ManagerItem) => {
            const newAssetAndCashflowItem = new MarketValueAndCashflowItem(
                item.type,
                null,
                manager.tickerIds[item.type],
                dayjs(item.effectiveDate).format('YYYY-MM-DDT00:00:00'),
                '',
                false,
                {
                    new: true,
                    edited: false,
                    show: true,
                    isNewColumn: true,
                }
            );
            manager.assetValuesAndCashflows.push(newAssetAndCashflowItem);
        });
        this.showAddColumnPopup = false;
    }

    public updateAssetValue (emittedData: {
        item: MarketValueAndCashflowItem<string, null | number>;
        managerIndex: number;
        itemIndex: number;
    }): void {
        Vue.set(
            this.localManagerDataWithPrefills[emittedData.managerIndex]
                .assetValuesAndCashflows,
            emittedData.itemIndex,
            emittedData.item
        );
    }


    //#endregion

    //#region all computed properties
    get dataToBeSaved (): ManagerItem[] {
        const managersWithDataToBeSaved: ManagerItem[] = [];

        this.localManagerDataWithPrefills.forEach(manager => {
            manager.assetValuesAndCashflows.forEach(assetItem => {
                if (assetItem.meta.edited || assetItem.isDelete) {
                    const managerAlreadyInList = managersWithDataToBeSaved.findIndex(m => m.id === manager.id);
                    if (managerAlreadyInList >= 0) {
                        const assetAlreadyInList = managersWithDataToBeSaved[managerAlreadyInList].assetValuesAndCashflows.findIndex(a =>
                            assetItem.type === a.type && assetItem.effectiveDate === a.effectiveDate
                        );
                        if (assetAlreadyInList >= 0) {
                            managersWithDataToBeSaved[managerAlreadyInList].assetValuesAndCashflows[assetAlreadyInList] = assetItem;
                        } else {
                            // now start populating
                            managersWithDataToBeSaved[managerAlreadyInList].assetValuesAndCashflows.push(assetItem);
                        }
                    } else {
                        const newManager: ManagerItem = cloneDeep(manager);
                        newManager.assetValuesAndCashflows = [];
                        newManager.assetValuesAndCashflows.push(assetItem);
                        managersWithDataToBeSaved.push(newManager);
                    }
                }
            }
            );
        }
        );

        return managersWithDataToBeSaved;
    }

    get sortedManagerData (): ManagerItem[] {
        return this.localManagerDataWithPrefills.map(
            (manager: ManagerItem): ManagerItem => {
                manager.assetValuesAndCashflows.sort(compare);
                return manager;
            }
        );
    }

    get uniqueDatesAndTypes (): ColumnHeading[] {
        let uniqueArrayOfDates: ColumnHeading[] = [];
        this.sortedManagerData.forEach((managerItem: ManagerItem) => {
            managerItem.assetValuesAndCashflows.forEach((assetValueItem: MarketValueAndCashflowItem<string | number, number>, assetIndex: number) => {
                // now check the date and see if it is in the uniqueArrayOfDates
                if (assetValueItem.meta.show) {
                    let tickerAsNumber;
                    if (assetValueItem.tickerValue !== '') {
                        tickerAsNumber = typeof assetValueItem.tickerValue !== 'number' ? parseFloat(assetValueItem.tickerValue) : assetValueItem.tickerValue;
                    } else {
                        tickerAsNumber = 0;
                    }
                    if (!uniqueArrayOfDates[assetIndex]) {
                        //create a new entry in array
                        uniqueArrayOfDates[assetIndex] = {
                            effectiveDate: assetValueItem.effectiveDate,
                            type: assetValueItem.type,
                            total: tickerAsNumber,
                        };
                    } else {
                        let currentTotal = uniqueArrayOfDates[assetIndex].total;
                        uniqueArrayOfDates[assetIndex] = {
                            effectiveDate: assetValueItem.effectiveDate,
                            type: assetValueItem.type,
                            total: currentTotal += tickerAsNumber,
                        };
                    }
                }
            });
        });

        return uniqueArrayOfDates;
    }


    get showUnSavedMessage (): boolean {
        return this.$store.state.showUnSavedMessage;
    }

    get isManagerDataAvailable (): boolean {
        return this.localManagerData.length > 0;
    }

    get routeFullPath (): string {
        return this.$route.name;
    }

    @Watch('routeFullPath')
    private routeChanged () {
        this.fetchValues();
    }

    @Watch('dataToBeSaved')
    private setUnsavedData (dataToBeSaved: ManagerItem[]) {
        this.$store.commit('updateUnsavedData', dataToBeSaved.length > 0);
    }

    @Watch('uniqueDatesAndTypes')
    private findNewColumnIx (newSorted: ManagerItem[], oldSorted: ManagerItem[]) {
        if (newSorted && oldSorted && newSorted.length > oldSorted.length) {
            let oldObjValues: Record<string, Record<string, boolean>> = {
                MMV: {},
                CFNET3M: {},
            };
            let index = null;
            oldSorted.map(
                (mgItem: any) =>
                    (oldObjValues[mgItem.type][mgItem.effectiveDate] = true)
            );
            newSorted.map((mgItem: any, ix) =>
                !oldObjValues[mgItem.type][mgItem.effectiveDate] ? (index = ix) : null
            );
            this.newColumnIndex = index;
        }
    }

    @Watch('newColumnIndex')
    private scrollToNewColumn (newColumnIndex: null | number) {
        if (newColumnIndex !== null) {
            setTimeout(() => {
                let column: HTMLDivElement = document.querySelector(
                    `.column-head-${newColumnIndex}`
                );
                if (column) {
                    this.managerTable.scrollLeft = column.clientWidth * newColumnIndex;
                    if (this.managerTable.style.scrollBehavior !== 'smooth') {
                        this.managerTable.style.scrollBehavior = 'smooth';
                    }
                }
            }, 0);
        }
    }
    //#endregion
}
