import { TEntityTypeDto } from '@/interfaces/dto/IEntityTypeDto';
import AssetAndMarketValueItem from '@/models/AssetAndMarketValueItem';

class MarkValueAndCashFlow {
  name!: string;

  id!: number;

  assetValuesAndCashflows!: AssetAndMarketValueItem<
    number | string,
    null | number
  >[];

  tickerIds!: Record<string, number>;

  parentEntityName! : string;

  entityTypeId!: TEntityTypeDto;

  constructor (
      name: string,
      id: number,
      assetValuesAndCashflows: Array<AssetAndMarketValueItem<number | string, null | number>>,
      tickerIds: Record<string, number>,
      parentEntityName: string,
      entityTypeId: TEntityTypeDto
  ) {
      this.name = name;
      this.id = id;
      this.assetValuesAndCashflows = assetValuesAndCashflows;
      this.tickerIds = tickerIds;
      this.parentEntityName = parentEntityName;
      this.entityTypeId = entityTypeId;
  }
}

export default MarkValueAndCashFlow;
